import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ProviderWithRouter } from '#models/login';
import { set } from '#utils/globalVariable';
import MyApp from '../App';
// import * as serviceWorker from './serviceWorker';

set({ env: 'WEB' });
ReactDOM.render(
  // <Provider store={store}>
  //   <Router>
  //     <MyApp />
  //   </Router>
  // </Provider>,
  <Router>
    <ProviderWithRouter>
      <MyApp />
    </ProviderWithRouter>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
